import { useAuthStore } from '~~/stores/auth'
import { useAlertStore } from '~/stores/alert'

export default defineNuxtRouteMiddleware(async (_to, _from) => {
  const { user } = useAuthStore()
  const alertStore = useAlertStore()

  if (!user) {
    alertStore.setAlert('Please log in to view this page', 'error')
    return navigateTo({
      path: '/login',
      query: {
        from: _to.fullPath,
      },
    })
  }
})
